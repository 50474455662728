import './App.css';
import HeaderNavigation from "./components/header/header-navigation";
import Footer from "./components/footer/footer";
import BaseRoutes from "./components/routes/base-routes";

function App() {
  return (
      <div className="App">
        <HeaderNavigation />
        <div className="Body-and-footer">
          <div className="App-router-output">
              <BaseRoutes />
          </div>
          <Footer />
        </div>
      </div>
  );
}

export default App;
