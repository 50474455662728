import "./Gallery.css";
import {JSX, useMemo, useState} from "react";

type ImageGroupType = "resort" | "smith-valley" | "wellington-station";

interface ImageGroup  {
    name: ImageGroupType,
    // type: [<tall count>, <wide count>]
    type: number[],
}

const Gallery = () => {
    const [imageGroup, setImageGroup] = useState<ImageGroupType>("resort");

    const {resort, smithValley, wellington} = useMemo(() => {
        const resortImages: JSX.Element[] = [];
        const smithValleyImages: JSX.Element[] = [];
        const wellingtonImages: JSX.Element[] = [];
        const imageObj: ImageGroup[] = [
            {
                name: "resort",
                type: [13, 24],
            },
            {
                name: "smith-valley",
                type: [3, 7],
            },
            {
                name: "wellington-station",
                type: [5, 2],
            },
        ];
        for (let i = 0; i < imageObj.length; i++) {
            for (let j = 0; j < imageObj[i].type.length; j++) {
                for (let k = 0; k < imageObj[i].type[j]; k++) {
                    if (i === 0) {
                        resortImages.push(
                            <img className="Gallery-image"
                                 key={`image-${imageObj[i].name}-${i}`}
                                 alt={`Around the park - ${imageObj[i].name}`}
                                 src={require(`../../assets/images/${imageObj[i].name}/${j === 0 ? "tall" : "wide"}-${k + 1}.jpg`)}
                            />
                        );
                    }
                    if (i === 1) {
                        smithValleyImages.push(
                            <img className="Gallery-image"
                                 key={`image-${imageObj[i].name}-${i}`}
                                 alt={`Around the park - ${imageObj[i].name}`}
                                 src={require(`../../assets/images/${imageObj[i].name}/${j === 0 ? "tall" : "wide"}-${k + 1}.jpg`)}
                            />
                        );
                    }
                    if (i === 2) {
                        wellingtonImages.push(
                            <img className="Gallery-image"
                                 key={`image-${imageObj[i].name}-${i}`}
                                 alt={`Around the park - ${imageObj[i].name}`}
                                 src={require(`../../assets/images/${imageObj[i].name}/${j === 0 ? "tall" : "wide"}-${k + 1}.jpg`)}
                            />
                        );
                    }
                }
            }
        }

        return {
            resort: resortImages,
            smithValley: smithValleyImages,
            wellington: wellingtonImages,
        };
    }, []);

    return (
        <>
            <div className="Gallery-container">
                <div className="Gallery-image-group">
                    <span className={
                        `Gallery-image-group-btn ${
                            imageGroup === "resort" ? "Gallery-image-group-selected" : ""
                        }`
                    }
                          onClick={() => setImageGroup("resort")}
                    >Around the Resort</span>
                    <span className={
                        `Gallery-image-group-btn ${
                            imageGroup === "smith-valley" ? "Gallery-image-group-selected" : ""
                        }`
                    }
                          onClick={() => setImageGroup("smith-valley")}
                    >Around Smith Valley</span>
                    <span className={
                        `Gallery-image-group-btn ${
                            imageGroup === "wellington-station" ? "Gallery-image-group-selected" : ""
                        }`
                    }
                          onClick={() => setImageGroup("wellington-station")}
                    >Around Wellington Station</span>
                </div>
                {imageGroup === "resort" && (
                    <div className="Gallery-images">
                        {resort.map((image) => (
                            image
                        ))}
                    </div>
                )}
                {imageGroup === "wellington-station" && (
                    <div className="Gallery-images">
                        {wellington.map((image) => (
                            image
                        ))}
                    </div>
                )}
                {imageGroup === "smith-valley" && (
                    <div className="Gallery-images">
                        {smithValley.map((image) => (
                            image
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};

export default Gallery;
