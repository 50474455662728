import "./Home.css";

const Home = () => {
    return <div className="Home-container">
        <div className="Home-hero">
            <div className="Home-hero-text">
                <h1>Welcome to our peaceful, resort community.</h1>
                <h2>
                    Located on the Walker River and right next to
                    Hoye Canyon and the Toiyabe National Forest.
                </h2>
            </div>
        </div>
        <div className="Home-hero-sub-text">
            <h2>30 Acres in the wilderness</h2>
            <div className="Home-about">
                Wellington Station Resort is situated on 30 acres,
                right next to Hoye Canyon and the Toiyabe National Forest.
                The Walker River flows through the property year-round
                and the majority of campsites sit along the riverfront.
            </div>
            <div className="Home-about">
                Long term residents are a 55-and-over community and we
                welcome people of all ages to stay for up to three months.
            </div>
        </div>
    </div>;
}

export default Home;
