import "./Contact.css";

const Contact = () => {
    return (
        <div className="Contact-page">
            <div className="Contact-container">
                <div className="Contact-text">
                    <div className="Contact-text-title">
                        We'd love to hear from you.
                    </div>
                    <div className="Contact-text-body">
                        If you have any questions, comments, or concerns, please fill out the form below and we will get
                        back to you as soon as possible.
                    </div>
                </div>
                <iframe className="Contact-iframe"
                        title="Contact us"
                        src="https://wellingtonstation.com/forms/contact-wellington-station-send-message.html"></iframe>
            </div>
        </div>
    );
}

export default Contact;
