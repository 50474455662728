import "./footer.css";

const Footer = () => {
    return <>
        <div className="Footer-container">
            <div className="Footer-email-list">
                <h3 className="Join-email-txt">Join our email list and get notified when spaces become available.</h3>
                <iframe className="Iframe"
                        src="https://wellingtonstation.com/forms/contact-wellington-station-join-email-list.html"
                        title="Wellington Station - Join email list"
                ></iframe>
                <div className="Footer-contact-info">
                    <div>
                        Wellington Station Resort |
                        2855 State Route 208 - Wellington, NV 89444
                    </div>
                    <div>
                        Phone: <a href="tel:17754652409">(775) 465-2409</a>
                    </div>
                    <div>
                        Email: contact@wellingtonstation.com
                    </div>
                </div>
            </div>
        </div>
    </>;
};

export default Footer;
