import "./About.css";

const About = () => {
    return (
        <>
            <div className="About-page">
                <div className="About-container">
                    <div className="About-text">
                        Wellington Station Resort is situated on 30 acres,
                        right next to Hoye Canyon and the Toiyabe National Forest.
                        The Walker River flows through the property year-round
                        and the majority of campsites sit along the riverfront.
                        Long term residents are a 55-and-over community and we
                        welcome people of all ages to stay for up to three months.
                    </div>
                    <div className="About-map">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3111.2144435464606!2d-119.38430552362757!3d38.7587852548704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80982d615e3f1753%3A0xbeb8aec418467594!2sWellington%20station%20rv%20park!5e0!3m2!1sen!2sus!4v1717734057642!5m2!1sen!2sus"
                            className="About-map-iframe"
                            title="Google Map - Wellington Station"
                            style={{border: 0}}
                            allowFullScreen={true}
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
            <div className="About-leave-review">
                <div>
                    Have you visited with us in the past? Did you enjoy your stay?
                </div>
                <h3>
                    Consider leaving us a review on Google Maps!
                </h3>
            </div>
        </>
    );
}

export default About;
