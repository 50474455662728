import "./routes.css";
import Home from "../../pages/home/Home";
import { Route, Routes } from "react-router-dom";
import About from "../../pages/about/About";
import Contact from "../../pages/contact/Contact";
import Gallery from "../gallery/Gallery";

const BaseRoutes = () => {
    return <>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="*" element={<Home />} />
        </Routes>
    </>;
}

export default BaseRoutes;
