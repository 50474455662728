import "./header-navigation.css";
import { Link } from "react-router-dom";
import {useState} from "react";

const HeaderNavigation = () => {
    const [displayMobileMenu, setDisplayMobileMenu] = useState<boolean>(false);
    return (
        <>
            <div className="Header-nav-container">
              <Link to="/home" className="Nav-link">Home</Link>
              <Link to="/about" className="Nav-link">About</Link>
              <div className="Nav-link-logo">
                <img src={require("../../assets/images/WSR_Logo_transparent.png")}
                     alt="WSR Logo"
                     className="Logo"
                />
              </div>
              <Link to="/contact" className="Nav-link">Contact</Link>
              <Link to="/gallery" className="Nav-link">Gallery</Link>
            </div>
            <div className="Mobile-menu">
                <div className="Mobile-menu-bar">
                    <div>
                        <a href="tel:+17754652409">
                            <i className="fa fa-phone Mobile-menu-icons" aria-hidden="true"></i>
                        </a>
                    </div>
                    <div className="Mobile-menu-logo-container">
                        <img src={require("../../assets/images/WSR_Logo_transparent.png")}
                             alt="WSR Logo - mobile menu"
                             className="Mobile-menu-logo"
                        />
                    </div>
                    <div onClick={() => setDisplayMobileMenu(!displayMobileMenu)}>
                        <i className="fa fa-bars Mobile-menu-icons"></i>
                    </div>
                </div>
                {displayMobileMenu && (
                    <div className="Mobile-menu-links"
                         onClick={() =>setDisplayMobileMenu(!displayMobileMenu)}>
                        <Link to="/home" className="Nav-link">Home</Link>
                        <Link to="/about" className="Nav-link">About</Link>
                        <Link to="/contact" className="Nav-link">Contact</Link>
                        <Link to="/gallery" className="Nav-link">Gallery</Link>
                    </div>
                )}
            </div>
        </>);
}

export default HeaderNavigation;
